export const routes = [
    {
        name:'Home',
        path:'/'
    },
    // {
    //     name:'Product',
    //     path:'/product'
    // },
    {
        name:'About',
        path:'/about'
    },
    {
        name:'Projects',
        // path:'/product'
        dropdown: true
    },
    {
        name:'LIP scheme',
        path:'/infra'
    },
    {
        name:'Gallery',
        path:'/gallery'
    },
    
    {
        name:'Contact Us',
        path:'/contact'
    },
   
    {
        name:'Register',
        path:'/registration',
        // // dropdown: true,
        // paths:[
        //     {
        //         name:"Registration",
        //         path:"registration"
        //     },
        //     {
        //         name:"Vendorlogin",
        //         path:"vendorlogin"
        //     },
        //     {
        //         name:'Login',
        //         path:'login'
        //     }
        // ],
    },
]


// registratio


// vendorlogin