const offers = [
    `50% Cash & 50% Emi Plots for Sale" @Kodaikanal - Pannaikadu`,
`Area @: Start from 1500sqft`,
`Offer : Pay 50% Cash Get Registration After Pay 50% Interest Free EMI`,
`EMI Duration : 24 month`,
`Interest : 0%`,
]
export const ammenities = [
    `DTCP & RERA Approved`,
    `Gated Community with Security`,
    `Black Top Road`,
    `Concrete Road`,
    `EB available`,
    `Kids Play Area`,
    `Park available`,
    `Conference Hall`,
    `Swimming Pool`,
    `Fitness center`,
    `Resturant`,
    `Wellness & Spa`,
    `Indoor Games`,
]

export default offers